
import MBaseModal from "@/components/MBaseModal.vue";
import { Options, Vue } from "vue-class-component";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import MButton from "@/components/MButton.vue";
import MLinkList from "@/components/form/MLinkList.vue";
import MsStudentPicker from "@/components/student/MsStudentPicker.vue";

import {
  convertToUnixtimeFromDateAndTime,
  convertToTimeFromUnixtime,
  convertToDateFromUnixtime
} from "@/utils/date";
import {
  updateSubmissionMaster,
  deleteSubmissionMaster
} from "@/api/submission_master";
import { updateThreadMaster, deleteThreadMaster } from "@/api/thread_master";
import { Student } from "@/entities/student";
import { SubmissionMaster, RelatedLink } from "@/entities/submission_master";
import store from "@/store";
import { saveErrorLog } from "@/api/error";
import dayjs from "dayjs";
@Options({
  components: {
    MBaseModal,
    MSelectBox,
    MTextField,
    MTextArea,
    MButton,
    MLinkList,
    MsStudentPicker
  },
  emits: ["close"],
  props: {
    submissionMaster: Object
  }
})
export default class SubmissionMasterEditModal extends Vue {
  name = "";
  enteredName = false;
  label = "";
  enteredLabel = false;
  classroomIds: string[] = [];
  submittableDate = dayjs().format("YYYY-MM-DD");
  closedDate = "";
  closedTime = "23:59";
  displayEndDate = "";
  submissionMaster!: SubmissionMaster;
  description = "";
  relatedLinks: RelatedLink[] = [];
  selectedStudents: Student[] = [];
  willSendMessageForUnsubmitted = true;
  useManagement = false;
  submissionMasters: {
    [key: string]: string | number | boolean;
  }[] = [];

  get validData() {
    if (this.useManagement) {
      return (
        this.name !== "" &&
        this.label !== "" &&
        this.selectedStudents.length > 0 &&
        this.submittableDate &&
        this.closedAt &&
        this.closedAt > this.submittableFrom &&
        this.closedAt > this.now &&
        (this.displayEndAt === 0 || this.displayEndAt > this.closedAt) &&
        this.relatedLinks.every(link => link.label && link.url) &&
        !this.isUniqueSubmissionMasterName
      );
    } else {
      return (
        this.name !== "" &&
        this.label !== "" &&
        this.selectedStudents.length > 0 &&
        this.submittableDate &&
        (this.displayEndAt === 0 || this.displayEndAt > this.now) &&
        this.relatedLinks.every(link => link.label && link.url) &&
        !this.isUniqueSubmissionMasterName
      );
    }
  }

  get isUniqueSubmissionMasterName() {
    return this.submissionMasters.some(
      s => s.name === `${this.name}_${this.label}`
    );
  }

  get now() {
    return dayjs().unix();
  }
  get closedAt() {
    if (!this.closedDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.closedDate, this.closedTime);
  }
  get submittableFrom() {
    if (!this.submittableDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.submittableDate, "0");
  }

  get displayEndAt() {
    if (!this.displayEndDate) return 0;
    return convertToUnixtimeFromDateAndTime(this.displayEndDate, "0");
  }
  async updateSubmission() {
    if (!store.state.school) {
      return;
    }
    try {
      this.selectedStudents.forEach(async student => {
        const classroomId = student.ref.parent?.parent?.id ?? "";
        if (this.classroomIds.includes(classroomId)) return; // 同じclassroomIdが配列に存在していた場合は以下のループ処理を飛ばす
        this.classroomIds.push(classroomId);
      });
      if (this.useManagement) {
        await updateSubmissionMaster(this.submissionMaster.ref, {
          name: this.name,
          label: this.label,
          studentRefs: this.selectedStudents.map(s => s.ref),
          submittableFrom: this.submittableFrom,
          closedAt: this.closedAt,
          displayEndAt: this.displayEndAt,
          classroomIds: this.classroomIds,
          description: this.description,
          relatedLinks: this.relatedLinks,
          willSendMessageForUnsubmitted: this.willSendMessageForUnsubmitted,
          deleted: false
        });
      } else {
        await updateThreadMaster(this.submissionMaster.ref, {
          name: this.name,
          label: this.label,
          studentRefs: this.selectedStudents.map(s => s.ref),
          submittableFrom: this.submittableFrom,
          displayEndAt: this.displayEndAt,
          classroomIds: this.classroomIds,
          description: this.description,
          relatedLinks: this.relatedLinks
        });
      }

      this.close();
    } catch (e) {
      alert("提出物の変更に失敗しました");
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to create submission"
      );
      return;
    }
  }

  async deleteSubmissionMaster() {
    const confirmRes = confirm(
      "提出物マスターを本当に削除しますか？\n⚠️この提出物マスターに紐づく生徒の提出物、関連リンクにアップロードしたファイルも削除され、復元できなくなります。"
    );
    if (!confirmRes) {
      return;
    }
    store.commit("START_LOADING", "削除中...");
    try {
      if (this.useManagement) {
        await deleteSubmissionMaster(this.submissionMaster.ref);
      } else {
        await deleteThreadMaster(this.submissionMaster.ref);
      }
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete submissionMaster"
      );
      return;
    }
    this.$router.go(0);
  }

  onStudentsUpdated(students: Student[]) {
    this.selectedStudents = students;
  }

  async onUpdateRelatedLinks(next: RelatedLink[]) {
    await this.submissionMaster.ref.update({
      relatedLinks: next
    });
    this.relatedLinks = next;
  }

  close() {
    this.$emit("close");
  }

  async created() {
    this.name = this.submissionMaster.data.name;
    this.label = this.submissionMaster.data.label;
    if (this.submissionMaster.data.studentRefs) {
      this.selectedStudents = this.submissionMaster.data.studentRefs
        .map(ref => store.state.students.find(s => s.ref.id === ref.id)!)
        .filter(_ => _);
    } else {
      this.selectedStudents = [];
    }
    this.submittableDate = convertToDateFromUnixtime(
      this.submissionMaster.data.submittableFrom
    );
    if (this.submissionMaster.data.displayEndAt)
      this.displayEndDate = convertToDateFromUnixtime(
        this.submissionMaster.data.displayEndAt
      );
    this.closedDate = this.submissionMaster.data.closedAt
      ? convertToDateFromUnixtime(this.submissionMaster.data.closedAt)
      : "";
    this.closedTime = this.submissionMaster.data.closedAt
      ? convertToTimeFromUnixtime(this.submissionMaster.data.closedAt)
      : "";

    this.description = this.submissionMaster.data.description ?? "";
    this.relatedLinks = this.submissionMaster.data.relatedLinks ?? [];
    this.willSendMessageForUnsubmitted =
      this.submissionMaster.data.willSendMessageForUnsubmitted !== false;
    this.useManagement = !!this.submissionMaster.data.closedAt;
  }
}
