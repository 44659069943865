
import store from "@/store";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MLoadingIcon from "@/components/MLoadingIcon.vue";
import MTable from "@/components/MTable.vue";
import MTableConditionItem from "@/components/MTableConditionItem.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel from "@/components/MHeaderPanel.vue";
import MsMultiRunDropDown from "@/components/student/MsMultiRunDropDown.vue";
import { Options, Vue } from "vue-class-component";
import { TableDefinition } from "@/utils/table";
import SubmissionMasterAddModal from "@/components/submission/SubmissionMasterAddModal.vue";
import SubmissionMasterEditModal from "@/components/submission/SubmissionMasterEditModal.vue";
import SubmissionMasterCopyModal from "@/components/submission/SubmissionMasterCopyModal.vue";
import { State } from "@/store/helper";
import { School } from "@/entities/school";
import { TutorMeta } from "@/api/tutor";
import { SubmissionMaster } from "@/entities/submission_master";
import dayjs from "dayjs";
import MTextField from "@/components/form/MTextField.vue";
import { submissionMasterDefinitions } from "@/utils/table";
import { getCurrentUnixtime } from "@/utils/date";

const definitions: TableDefinition[] = submissionMasterDefinitions;

type TermString =
  | "過去4週間"
  | "過去7日間"
  | "本日"
  | "未来7日間"
  | "未来4週間"
  | "";
type CheckboxData = {
  id: string;
  value: TermString;
};
const closedAtTerms: CheckboxData[] = [
  {
    id: "past-weeks",
    value: "過去4週間"
  },
  {
    id: "past-days",
    value: "過去7日間"
  },
  {
    id: "today",
    value: "本日"
  },
  {
    id: "future-days",
    value: "未来7日間"
  },
  {
    id: "future-weeks",
    value: "未来4週間"
  }
];

@Options({
  components: {
    MButton,
    MIcon,
    MLoadingIcon,
    MTable,
    MTableConditionItem,
    MNavBar,
    MHeaderPanel,
    MsMultiRunDropDown,
    SubmissionMasterAddModal,
    SubmissionMasterEditModal,
    SubmissionMasterCopyModal,
    MTextField
  },
  watch: {
    closedAtTerm() {
      if (
        this.closedAtTerm === "過去4週間" ||
        this.closedAtTerm === "過去7日間" ||
        this.closedAtTerm === "本日" ||
        this.closedAtTerm === "未来7日間" ||
        this.closedAtTerm === "未来4週間"
      ) {
        this.loadSubmissionMaster();
      }
    }
  }
})
export default class SubmissionMasterList extends Vue {
  @State("school") school!: School | null;
  @State("tutor") tutor!: TutorMeta | null;
  @State("load", "submissionMaster") load!: boolean;
  @State("submissionMasters", "submissionMaster")
  submissionMasters!: SubmissionMaster[];

  @State("closedAtTerm", "submissionMaster") closedAtTerm!: string;
  @State("termStart", "submissionMaster") termStart!: string;
  @State("termStartTime", "submissionMaster") termStartTime!: number;
  @State("termEnd", "submissionMaster") termEnd!: string;
  @State("termEndTime", "submissionMaster") termEndTime!: number;

  definitions = definitions;
  isDisplayedAddSubmissionMasterModal = false;
  isDisplayedEditSubmissionMasterModal = false;
  isDisplayedCopySubmissionMasterModal = false;
  maxStorageSizeGB = 0;
  allocatedStorageSizeGB = 0;
  allocatedPercent = 0;
  selectedId = "";
  selectedIds: string[] = [];
  closedAtTerms = closedAtTerms;
  showPastDateForDisplayEndDate = false;
  query = "";

  tableData: {
    [key: string]: string | number | boolean;
  }[] = [];

  get submissionMaster(): SubmissionMaster | null {
    return (
      this.submissionMasters.find(
        submissionMaster => submissionMaster.ref.id === this.selectedId
      ) ?? null
    );
  }

  get filteredTableData() {
    if (this.showPastDateForDisplayEndDate) return this.tableData;
    return this.tableData
      .filter(
        t => t.displayEndAt === 0 || t.displayEndAt > getCurrentUnixtime()
      )
      .filter(t =>
        (t.name as string).toLowerCase().includes(this.query.toLowerCase())
      );
  }

  changeShowDisplayEndDateFlag() {
    this.showPastDateForDisplayEndDate = !this.showPastDateForDisplayEndDate;
  }

  changeClosedAtTerm(term: string) {
    store.commit("submissionMaster/SET_CLOSED_AT_TERM", term);
  }

  changeTermStart(start: string) {
    store.commit("submissionMaster/SET_TERM_START", start);
  }

  changeTermEnd(end: string) {
    store.commit("submissionMaster/SET_TERM_END", end);
  }

  showAddModal() {
    this.isDisplayedAddSubmissionMasterModal = true;
  }

  showEditModal() {
    this.isDisplayedEditSubmissionMasterModal = true;
  }

  showCopyModal() {
    this.isDisplayedCopySubmissionMasterModal = true;
  }

  closeAddModal() {
    this.isDisplayedAddSubmissionMasterModal = false;
    this.loadSubmissionMaster();
  }

  closeEditModal() {
    this.isDisplayedEditSubmissionMasterModal = false;
    this.loadSubmissionMaster();
  }

  closeCopyModal() {
    this.isDisplayedCopySubmissionMasterModal = false;
    this.loadSubmissionMaster();
  }

  changeSelectedId(id: string) {
    this.selectedId = id;
    this.showEditModal();
  }

  copySelectedId(id: string) {
    this.selectedId = id;
    this.showCopyModal();
  }

  async loadSubmissionMaster() {
    const schoolId = this.school?.ref.id ?? "";
    const classroomIds =
      this.tutor?.config.data.classrooms.map(e => e.id) ?? [];
    await store.dispatch("submissionMaster/getSubmissionMaster", {
      schoolId,
      classroomIds
    });
    this.submissionMasters.sort((a, b) => {
      //締切日で昇順ソート
      if (!a.data.closedAt) return 1;
      else if (!b.data.closedAt) return -1;
      else return a.data.closedAt - b.data.closedAt;
    });
    this.tableData = await Promise.all(
      this.submissionMasters.map(async e => {
        const submittableFrom = dayjs(e.data.submittableFrom * 1000)
          .locale("ja")
          .format("YYYY/M/D");
        const displayEndDate = e.data.displayEndAt
          ? dayjs(e.data.displayEndAt * 1000)
              .locale("ja")
              .format("YYYY/M/D")
          : "-";
        const closedAt = e.data.closedAt
          ? dayjs(e.data.closedAt * 1000).locale("ja")
          : null;
        const closedAtDate = closedAt?.format("YYYY/M/D HH:mm") ?? "なし";
        return {
          id: e.ref.id,
          name: e.data.label ? `${e.data.name}_${e.data.label}` : e.data.name,
          studentNum: e.data.studentRefs?.length ?? 0,
          submittableFrom,
          "pre-submittableFrom": e.data.submittableFrom ?? 0,
          displayEndDate,
          "pre-displayEndDate": e.data.displayEndAt ?? 0,
          displayEndAt: e.data.displayEndAt ?? 0,
          closedAtDate,
          "pre-closedAtDate": closedAt?.unix() ?? 0,
          link: e.data.closedAt
            ? `/submission_master/${e.ref.id}`
            : `/thread_master/${e.ref.id}`
        };
      })
    );
  }

  async mounted() {
    // 提出物マスターの一覧を取得
    this.changeClosedAtTerm("");
    this.loadSubmissionMaster();

    // 使用済のストレージと使用可能量（生徒数 × 1GB）を GB で表示
    const allocated = store.state.school?.data.allocatedStorageSize ?? 0;
    this.allocatedStorageSizeGB =
      Math.round((allocated / Math.pow(1024, 3)) * 1000) / 1000;
    this.maxStorageSizeGB = (store.state.school?.data.studentCount ?? 0) * 1;
    const ratio = this.allocatedStorageSizeGB / this.maxStorageSizeGB;
    this.allocatedPercent =
      Math.round((!Number.isNaN(ratio) ? ratio : 0) * 100 * 1000) / 1000;
  }
}
